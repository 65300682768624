<template>
  <div>
    <AlterFormLayout
      modalId="inss-alter-modal"
      formTitle="Alterar informações de INSS"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar solicitação de Ateração"
      :onSubmitEvent="onSubmit"
      :disableEvent="this.$store.getters.isAdmin"
    >
      <template #content>
        <InssForm :loadedFormData="$props.loadedFormData" />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Sua solicitação de alteração
    das informações de INSS foi enviada."
      buttonText="Ok, entendi!"
      :protocol="this.protocol"
      subdescription="Os dados alterados serão
    analisados e atualizados após a validação."
    />
  </div>
</template>

<script>
import InssForm from "./InssForm.vue";
import AlterFormLayout from "../../../../../layouts/AlterFormLayout.vue";
import { updateInssInformation } from "../../../../../services/inss/inss-services";
import SuccessModal from "../../../../../components/base-modals/SuccessModal.vue";
import { getGeneralInformation } from "../../../../../services/general-information/general-information";

export default {
  props: ["loadedFormData"],
  components: {
    AlterFormLayout,
    InssForm,
    SuccessModal,
  },
  data() {
    return {
      protocol: "",
    };
  },
  methods: {
    async onSubmit(formData) {

      this.protocol = await updateInssInformation(formData, formData.files);
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();

      getGeneralInformation(this.$store.getters.getUserName)
        .then((userInformation) => {
          userInformation.nickname = userInformation.name
            ? userInformation.name.split(" ", 1)[0]
            : "Cooperado";          
          localStorage.setItem(
            "user-information",
            JSON.stringify(userInformation)
          );          

          this.$emit('userInfoUpdated')
        });
    },
  },
};
</script>
