<template>
  <b-container fluid class="general-information">
    <b-row class="justify-content-center mb-4">
      <p class="p4">
        Confira seus dados e, caso haja divergências, solicite alteração
        cadastral a qualquer momento.
      </p>
    </b-row>
    <div v-if="tableItems && tableItems.length && this.loading == 0">
      <b-row class="justify-content-center">
        <b-card class="flex-grow-1">
          <b-table
            class="custom-table spaced-text"
            stacked
            :fields="tableFields"
            :items="tableItems"
          >
          </b-table>
        </b-card>
      </b-row>
    </div>
    <b-row class="mb-4" v-show="this.loading == 0">
      <InssAddModal
        newRegister="true"
        :loadedFormData="tableItems[0]"
        :loadCategories="categoriesItems"
      />

      <InssAlterModal
        @userInfoUpdated="getInssInfo"
        :loadedFormData="tableItems[0]"
      />

      <b-button class="my-3" variant="outline-primary" v-b-modal:inss-add-modal>
        Cadastrar retenção por outras fontes
      </b-button>
      <div v-if="tableItems && tableItems.length > 0">
        <b-button
          class="my-3 ml-3"
          variant="primary"
          v-b-modal.inss-alter-modal
        >
          Alterar número de inscrição do INSS (PIS)
        </b-button>
      </div>
    </b-row>
    <b-row v-if="this.tableItemsRetention.length > 0 && !this.loading">
      <p class="retention-label">Retenção por outras fontes</p>
    </b-row>
    <b-row v-if="this.tableItemsRetention.length > 0 && !this.loading">
      <AccordionList
        titleKeyName="localName"
        :items="this.tableItemsRetention"
        :itemsFields="this.tableFieldsRetention"
      />
    </b-row>
    <div v-show="this.loading">
      <b-card>
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="tableFields.length"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
    </div>

    <SuccessModal
      description="Solicitação de alteração enviada!"
      buttonText="Ok, entendi!"
    />
  </b-container>
</template>

<script>
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import AccordionList from "../../../../components/accordion-list/AccordionList.vue";
import { formatMoney } from "../../../../utils/format/formtUtils";

import {
  getCategoriesESocial,
  getInssInformation,
  getRetention,
} from "../../../../services/inss/inss-services";
import InssAddModal from "../forms/inss/inssAddModal.vue";
import InssAlterModal from "../forms/inss/InssAlterModal.vue";

export default {
  name: "general-information",
  components: {
    SuccessModal,
    InssAlterModal,
    InssAddModal,
    AccordionList,
  },
  async mounted() {
    try {
      this.loading = true;
      const getPis = this.$store.getters.getUserInformation.pis;
      await this.getInssInfo(getPis);
      await this.getCategories();
      await this.getRetentions();
      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.error("getInssInfo", error);
    }
  },
  data() {
    return {
      tableFields: [
        {
          key: "pis",
          label: "Número de inscrição do INSS (PIS)",
          thClass: "text-left",
          tdClass: "text-right",
        },
      ],
      tableItems: [],
      tableFieldsRetention: [
        { key: "localName", label: "Local", tdClass: "text-right" },
        { key: "cnpj", label: "CNPJ", tdClass: "text-right" },
        { key: "value", label: "VALOR DA CONTRIBUIÇÃO", tdClass: "text-right" },
        {
          key: "socialCategory",
          label: "CATEGORIA DO E-SOCIAL",
          tdClass: "text-right",
        },
        { key: "dateStarted", label: "DATA INICIO", tdClass: "text-right" },
        { key: "dateFinished", label: "DATA FIM", tdClass: "text-right" },
      ],
      tableItemsRetention: [],
      categoriesItems: [],
      loading: 0,
    };
  },
  methods: {
    async getRetentions() {
      const tableItemsRetention = await getRetention();
      if (!tableItemsRetention) return;
      this.tableItemsRetention = tableItemsRetention.map((retention) => {
        retention.dateStarted = retention.dateStarted.replace(" 00:00:00", "");
        retention.dateFinished = retention.dateFinished.replace(
          " 00:00:00",
          ""
        );
        retention.value = "R$ " + formatMoney(retention.value);
        return retention;
      });
    },
    async getInssInfo(getPis) {
      const information = await getInssInformation(getPis);
      this.loading = false;
      this.tableItems = [
        {
          ...information,
        },
      ];
    },
    async getCategories() {
      const information = await getCategoriesESocial();
      this.categoriesItems = information;
    },
  },
};
</script>

<style lang="scss" scoped>
.general-information td::before {
  text-align: left !important;
}

.general-information td:first-child {
  border-color: transparent;
}

div.card {
  border-color: transparent;
  border-radius: 1rem;
}
.accordion-container {
  width: 100%;
}
.retention-label {
  font-size: 1.5rem;
  font-weight: bolder;
  color: #23272b;
  opacity: 1;
  font: normal normal normal 16px/50px Trebuchet MS;
}
</style>
