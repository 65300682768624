import { returnDateFormat } from "../../utils/date/date-utils";
import {
  formatCpfCnpj,
  formatMoney,
  formatPispasep,
} from "../../utils/format/formtUtils";

export function mapperToAddInssInformation(formData, requestAction) {

  return {
    "Ação da solicitação": requestAction,
    "INSS já retido em outra fonte": formData.retainedInAnotherSource,
    "Nome do local": formData.localName,
    CNPJ: formatCpfCnpj(formData.cnpj),
    "Valor da contribuição": formatMoney(formData.contributionAmount),
    "Categoria E-Social": formData.categoryOfESocial,
    "Data de início": returnDateFormat(formData.startDate),
    "Data de término": returnDateFormat(formData.endDate),
  };
}

export function mapperToAlterInssInformation(formData, requestAction) {
  return {
    "Ação da solicitação": requestAction,
    "Número do PIS": formatPispasep(formData.pis),
  };
}
