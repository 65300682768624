<template>
  <div>
    <AlterFormLayout
      modalId="inss-add-modal"
      formTitle="Cadastrar informações de INSS"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar solicitação de cadastro"
      :onSubmitEvent="onSubmit"
      :disableEvent="this.$store.getters.isAdmin"
    >
      <template #content>
        <InssForm
          newRegister="true"
          :loadedFormData="$props.loadedFormData"
          :loadCategories="$props.loadCategories"
        />
      </template>
    </AlterFormLayout>

    <success-modal
      ref="success-modal"
      description="Sua solicitação das informações de INSS foi enviada."
      buttonText="Ok, entendi!"
      subdescription="Os dados serão analisados e atualizados após a validação."
      :protocol="this.protocol"
    />
  </div>
</template>

<script>
import InssForm from "./InssForm.vue";
import AlterFormLayout from "../../../../../layouts/AlterFormLayout.vue";
import { addInssInformation } from "../../../../../services/inss/inss-services";
import SuccessModal from "../../../../../components/base-modals/SuccessModal.vue";
import { createToast } from '../../../../../components/toast/toast';

export default {
  props: ["loadedFormData", "loadCategories"],
  components: { AlterFormLayout, InssForm, SuccessModal },
  data() {
    return {
      protocol: "",
    };
  },
  methods: {
    async onSubmit(formData) {
      try {
        this.protocol = await addInssInformation(formData, formData.files);
        const modal = this.$refs["success-modal"].$children[0];
        modal.show();
      } catch(error) {
        if (error.response &&
            ( error.response.status == 409 || 
              (
                error.response.status == 400 &&
                error.response.data.error_code == "INSS_CRM_DATA_NOT_FOUND"
              )
            )
          ) {
            createToast(
            "Informação",
            error.response.data.message,
            "warning"
          )
        } else {
          console.error("error on AlterForm onSubmit: ", error);
          createToast("Atenção", "Ocorreu um erro ao salvar as informações.");
        } 
      }
    },
  },
};
</script>
