<template>
  <div>
    <p v-if="!$props.newRegister" class="p4">
      <i class="fas fa-info-circle"></i>
      Importante: 1 - Para alteração do número de inscrição do INSS é necessário
      enviar a comprovação. 2 - Caso o INSS não seja retido em outra fonte, será
      retido da produção médica do cooperado 20% do imposto do INSS.
    </p>
    <p v-if="$props.newRegister" class="p4">
      <i class="fas fa-info-circle"></i>
      Importante: Caso o INSS não seja retido em outra fonte, será retido da
      produção médica do cooperado 20% do imposto do INSS.
    </p>
    <b-card class="custom-card my-4" border-variant="light">
      <b-form-row v-if="!$props.newRegister">
        <b-col>
          Número de inscrição de INSS (PIS)
        </b-col>
        <b-col>
          <CustomMaskInput
            :initialValue="form.pis"
            :options="pisMaskOptions"
            :state="validateFieldPis('pis')"
          />
        </b-col>
      </b-form-row>
      <b-form-row v-if="$props.newRegister">
        <b-col>
          Nome do local
        </b-col>
        <b-col>
          <b-form-input
            v-model.lazy.trim="form.localName"
            :state="validateField('localName')"
            placeholder="Digite"
          />
        </b-col>
      </b-form-row>
      <b-form-row v-if="$props.newRegister">
        <b-col>
          CNPJ
        </b-col>
        <b-col>
          <CustomMaskInput
            :initialValue="form.cnpj"
            :options="cnpjMaskOptions"
            :state="validateCnpj('cnpj')"
            placeholder="Digite"
          ></CustomMaskInput>
        </b-col>
      </b-form-row>
      <b-form-row v-if="$props.newRegister">
        <b-col>
          Valor Salário Contribuição
        </b-col>
        <b-col>
          <CustomMaskInput
            :initialValue="form.contributionAmount"
            :options="currencyMaskOptions"
            :state="validateCurrency('contributionAmount')"
          ></CustomMaskInput>
        </b-col>
      </b-form-row>
      <b-form-row v-if="$props.newRegister">
        <b-col>
          Categoria do E-Social
        </b-col>
        <b-col>
          <b-form-select
            v-model="form.categoryOfESocial"
            :state="validateField('categoryOfESocial')"
            :options="categoriesOptions"
          />
        </b-col>
      </b-form-row>
      <b-form-row v-if="$props.newRegister">
        <b-col>
          Data de Início
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="A declaração de retenção do INSS por outras fontes deverá ser enviada até o dia 19 de cada mês."
            class="fas fa-info-circle color-primary p3 ml-1"
          ></i>
        </b-col>
        <b-col class="d-flex flex-column">
          <date-picker
            datePickerId="startDate"
            :key="startDateKey"
            :dataValue="form.startDate"
            :state="validateFieldStartDate('startDate')"
            @datePickerChangedValue="onChangedStartDate"
            :required="true"
          />
        </b-col>
        <div
          class="invalid text-invalid-right"
          v-show="validateFieldStartDate('startDate') == false"
        >
          {{ dataIncialErrorMsg }}
        </div>
      </b-form-row>
      <b-form-row v-if="$props.newRegister">
        <b-col>
          Data de Término
        </b-col>
        <b-col class="d-flex flex-column">
          <date-picker
            datePickerId="endDate"
            :key="endDateKey"
            :dataValue="form.endDate"
            :state="validateFieldEndDate('endDate')"
            @datePickerChangedValue="onChangedEndDate"
            :required="true"
          />
        </b-col>
        <div
          class="invalid text-invalid-right"
          v-show="validateFieldEndDate('endDate') == false"
        >
          {{ dataFinalErrorMsg }}
        </div>
      </b-form-row>
      <b-form-row>
        <b-col>
          <UploadFile
            @updateFile="onChangeFiles"
            :multiple="true"
            :description="'Anexar documento para comprovação'"
            :msgErro="'É NECESSÁRIO ANEXAR O DOCUMENTO PARA COMPROVAÇÃO.'"
            :state="validateFieldFile('files')"
            :required="true"
            v-model.lazy.trim="form.files"
          />
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import CustomMaskInput from "../../../../../components/forms/custom-mask-input/CustomMaskInput.vue";
import {
  formatDateToBootstrapPicker,
  formatStringHyphenToDate,
} from "../../../../../utils/date/date-utils";
import { cnpj } from "cpf-cnpj-validator";
import { inject } from "@vue/composition-api";
import UploadFile from "../../../../../components/upload-file/UploadFile.vue";
import { validateFieldFile } from "../../../../../utils/validate/validate.js";
import DatePicker from "../../../../../components/date-picker/DatePicker.vue";
const InvalidEventHandler = () => {
  console.warn("Invalid onChange handler");
};

const CnpjMaskOptions = {
  numericOnly: true,
  delimiters: [".", ".", "/", "-"],
  blocks: [2, 3, 3, 4, 2],
  onValueChanged: InvalidEventHandler,
};

const PisMaskOptions = {
  numericOnly: true,
  delimiters: [".", ".", "-"],
  blocks: [3, 5, 2, 1],
  onValueChanged: InvalidEventHandler,
};

const CurrencyMaskOptions = {
  numeral: true,
  numeralDecimalMark: ",",
  numeralDecimalScale: 2,
  delimiter: ".",
  prefix: "R$",
  rawValueTrimPrefix: true,
  onValueChanged: InvalidEventHandler,
};

export default {
  props: ["loadedFormData", "newRegister", "loadCategories"],
  components: { CustomMaskInput, UploadFile, DatePicker },
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");

    return { formData, setForm };
  },
  mounted() {
    const formData = this.$props.loadedFormData;

    const retainedInAnotherSource =
      formData.retainedInAnotherSource &&
      formData.retainedInAnotherSource.toLowerCase() == "sim";

    this.form = {
      ...this.form,
      ...formData,
      retainedInAnotherSource: retainedInAnotherSource,
      startDate: formatDateToBootstrapPicker(formData.startDate),
      endDate: formatDateToBootstrapPicker(formData.endDate),
    };
    this.form.originalData = { ...this.form };
    this.formData = {
      form: { ...this.form },
      onSubmitHandler: this.onSubmit,
    };

    if (this.$props.newRegister) {
      this.categoriesOptions = this.buildCategoriesOptions(
        this.$props.loadCategories
      );
    }
    this.startDateKey = this.form.startDate;
    this.endDateKey = this.form.endDate;
  },
  data() {
    return {
      endDateKey: null,
      startDateKey: null,
      formSubmited: false,
      validateFieldFile,
      dataIncialErrorMsg: null,
      dataFinalErrorMsg: null,
      form: {
        pis: null,
        retainedInAnotherSource: null,
        localName: null,
        cnpj: null,
        contributionAmount: null,
        categoryOfESocial: null,
        startDate: null,
        endDate: null,
        files: [],
        originalData: null,
      },
      validations: {},
      cnpjMaskOptions: {
        ...CnpjMaskOptions,
        onValueChanged: this.onChangeCnpj,
      },
      pisMaskOptions: {
        ...PisMaskOptions,
        onValueChanged: this.onChangePis,
      },
      currencyMaskOptions: {
        ...CurrencyMaskOptions,
        onValueChanged: this.onChangeContributionAmount,
      },
      categoriesOptions: [],
    };
  },
  methods: {
    onChangeContributionAmount(e) {
      this.form.contributionAmount = e.target.rawValue;
    },
    onChangePis(e) {
      this.form.pis = e.target.rawValue;
    },
    onChangeCnpj(e) {
      this.form.cnpj = e.target.rawValue;
    },
    validateCurrency(name) {
      let value = this.form[name];
      let validation = !!value;
      value = parseFloat(value);
      validation = validation && value >= 0;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return validation;
    },
    validateCnpj(name) {
      let value = this.form[name];
      let validation = cnpj.isValid(value);
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    validateFieldPis(name) {
      let value = this.form[name];
      let validation = true;
      validation = !!value;
      validation = validation && value.length == 11;

      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return validation;
    },
    validateCheckBox(name) {
      this.validations[name] = true;
      if (!this.formSubmited) return null;
      return true;
    },
    validateFieldEndDate(name) {
      let value = this.form[name];

      let validation = !!value;
      if (!this.form.startDate) {
        validation = false;
        this.dataFinalErrorMsg = "Data início deve ser informada.";
      } else if (this.form.endDate) {
        const today = new Date();
        const currentYear = today.getFullYear();
        const settedEndDate = new Date(this.form.endDate);
        const settedStartDate = new Date(this.form.startDate);
        const settedYear = settedEndDate.getFullYear();
        if (settedEndDate < settedStartDate) {
          validation = false;
          this.dataFinalErrorMsg =
            "A data fim deve ser posterior a data início.";
        }
        if (currentYear != settedYear) {
          validation = false;
          this.dataFinalErrorMsg = "Data fim somente do ano vigente.";
        }
      }
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    validateFieldStartDate(name) {
      let value = this.form[name];
      let validation = !!value;
      if (this.form.startDate) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const currentMonth = today.getMonth() + 1;
        const currentYear = today.getFullYear();
        const settedStartDate = formatStringHyphenToDate(this.form.startDate);
        const settedDay = settedStartDate.getDate();
        const settedMonth = settedStartDate.getMonth() + 1;
        const settedYear = settedStartDate.getFullYear();

        if (settedDay > 19 && currentMonth == settedMonth) {
          validation = false;
          this.dataIncialErrorMsg =
            "Data início após o dia 19 deve ser somente a partir do próximo mês.";
        }
        if (currentYear != settedYear) {
          validation = false;
          this.dataIncialErrorMsg = "Data início somente do ano vigente.";
        }
        if (today > settedStartDate) {
          validation = false;
          this.dataIncialErrorMsg =
            "Data início não pode ser anterior a data atual.";
        }
      } else {
        validation = false;
        this.dataIncialErrorMsg = "Data início obrigatória";
      }
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    validateField(name) {
      let value = this.form[name];

      let validation = !!value;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return !!value;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    onChangeFiles(files) {
      this.form.files = files;
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
    buildCategoriesOptions(categories) {
      return [
        { value: null, text: "selecione uma categoria" },
        ...categories.map((option) => ({
          value: option.codCategory,
          text: option.descCategory?.replace(/(.{61})..+/, "$1…"),
        })),
      ];
    },
    onChangedStartDate(value) {
      this.form.startDate = value;
    },
    onChangedEndDate(value) {
      this.form.endDate = value;
    },
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
  text-align: left;
}
.text-invalid-right {
  width: 100%;
  text-align: right;
}

@media screen and (max-width: 650px) {
  .card-body {
    padding: 0 !important;
  }
  .alter-form div.card {
    padding: 0 !important;
  }
}
.text-invalid-right {
  width: 100%;
  text-align: right;
}
</style>
